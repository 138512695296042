// src/App.js
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import SearchBar from './components/SearchBar';
import QuestionList from './components/QuestionList';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
// Import your logo image
// import logo from 'logo192.png';

function App() {
  const [selectedTags, setSelectedTags] = useState([]);
  const [questionsData, setQuestionsData] = useState({ count: 0, questions: [] });
  const [selectedExam, setSelectedExam] = useState("upsc");

  const navigate = useNavigate();
  const { exam } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (exam) setSelectedExam(exam);

    const tagsParam = searchParams.get("tags");
    if (tagsParam) {
      const tagsArray = tagsParam.split(',');
      setSelectedTags(tagsArray);
    }
  }, []);

  useEffect(() => {
    const tagsQuery = selectedTags.length ? `?tags=${selectedTags.join(',')}` : '';
    navigate(`/${selectedExam}${tagsQuery}`, { replace: true });
  }, [selectedExam, selectedTags, navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      if (selectedTags.length === 0) {
        setQuestionsData({ count: 0, questions: [] });
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/questions`, {
          params: { tags: selectedTags.join(',') }
        });
        setQuestionsData(response.data);
        // console.log('from app.js', response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [selectedTags]);


  // Set the page title dynamically
  useEffect(() => {
    document.title = selectedExam.toUpperCase() + " Questions - Turtle Rabbit";
  }, [selectedExam]); // Update title when selectedExam changes


  const handleLogoClick = () => {
    setSelectedTags([]);
    // if (window.location.pathname === '/') {
      // window.location.reload(); // Reload if already on the home page
    // }
  };

  return (
    <Container className="pt-3">
      {/* Logo at the top of the SearchBar */}
      <div className="text-center mb-3">
        <Link to='/' onClick={handleLogoClick}>
        <img src='/turtlerabbit_logo.png' alt="Logo" style={{ maxWidth: '200px', height: 'auto' }} />
        </Link>
      </div>

      <SearchBar
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedExam={selectedExam}
        setSelectedExam={setSelectedExam}
      />
      <QuestionList selectedTags={selectedTags} setSelectedTags={setSelectedTags} questionsData={questionsData} />
    </Container>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/:exam" element={<App />} />
      </Routes>
    </Router>
  );
}
