// src/components/QuestionCard.js
import React, { useState } from 'react';
import { Card, Form, Accordion, Badge } from 'react-bootstrap';

function QuestionCard({index, question, selectedTags, setSelectedTags}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const updateTags = (new_tag) => {
    if (!selectedTags.includes(new_tag)){
      setSelectedTags([...selectedTags, new_tag])
    }
  };
  

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5>{`Q ${index+1}.`}</h5>
        <p>{question.question}</p>
        <Form>
          {question.options.map((option, index) => (
            <Form.Check
              key={index}
              type="radio"
              label={option}
              name={`question-${question.id}`}
              checked={selectedOption === index}
              onChange={() => setSelectedOption(index)}
            />
          ))}
        </Form>

        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Select one option to reveal the answer</Accordion.Header>
            <Accordion.Body>
              ({question['correct_choice']}) {question['explanation']}
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Reference</Accordion.Header>
            <Accordion.Body>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>

        <div className="mt-3">
          {question.tags.map((tag, index) => (
            <Badge key={index} bg='light' text="dark" className='me-2' onClick={() => updateTags(tag)} style={{ cursor: 'pointer' }}>
              {tag} <span style={{ fontWeight: 'bold' }}>+</span>
              </Badge>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

export default QuestionCard;
